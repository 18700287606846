import { PAGE_INPUT_QUERY_KEY } from '../const';
import { createQueryParams } from './createQueryParams';

export const INTERFACE_BASE_ROUTE = '/interfaces';

export function getInterfacePageBuilderRoute(interfaceId: string, interfacePageSlug: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/builder/${interfacePageSlug}`;
}

export function getInterfacePreviewRoute({
  interfaceId,
  interfacePageSlug,
  pageInputs,
}: {
  interfaceId: string;
  interfacePageSlug: string;
  pageInputs?: Record<string, unknown>;
}) {
  let route = `${INTERFACE_BASE_ROUTE}/${interfaceId}/preview/${interfacePageSlug}`;

  if (pageInputs) {
    const query = createQueryParams(PAGE_INPUT_QUERY_KEY, pageInputs);
    route = `${route}?${query}`;
  }

  return route;
}

export function getInterfacePageNavigationBuilderURL(interfaceId: string, interfacePageId: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/builder/page/${interfacePageId}`;
}

export function getInterfacePageNavigationPreviewURL(interfaceId: string, interfacePageId: string) {
  return `${INTERFACE_BASE_ROUTE}/${interfaceId}/preview/page/${interfacePageId}`;
}
