import { useEffect } from 'react';
import { useMqttClient } from '@unifyapps/network/mqttClient';
import useMqttSubscription from '@unifyapps/network/hooks/useMqttSubscription';

export function useUserInboxSubscription({
  loggedInUserId,
  onNotificationReceive,
}: {
  loggedInUserId?: string;
  onNotificationReceive: (payload: Buffer) => void;
}) {
  const { connectClient, disconnectClient } = useMqttClient();

  useEffect(() => {
    void connectClient();
    return disconnectClient;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- disconnectClient depends on client which is set in connectClient
  }, [connectClient]);

  useMqttSubscription(`INBOX_U_${loggedInUserId}`, { onMessage: onNotificationReceive });
}
