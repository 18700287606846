/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CloneEntityBody } from '../models/cloneEntityBody';
import type { CloneEntityParams } from '../models/cloneEntityParams';
import type { Entity } from '../models/entity';
import type { EntityCopyRequest } from '../models/entityCopyRequest';
import type { EntityDistinctValuesRequest } from '../models/entityDistinctValuesRequest';
import type { EntityExportRequest } from '../models/entityExportRequest';
import type { EntityImportFieldMapping } from '../models/entityImportFieldMapping';
import type { EntityImportRequest } from '../models/entityImportRequest';
import type { EntityMutationResponse } from '../models/entityMutationResponse';
import type { HitsEntity1 } from '../models/hitsEntity1';
import type { ImportFieldMappingRequest } from '../models/importFieldMappingRequest';
import type { Query } from '../models/query';
import type { Update } from '../models/update';
import type { UpdateEntityByIdParams } from '../models/updateEntityByIdParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const createEntity = (entity: Entity, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entity,
    },
    options,
  );
};

export const getCreateEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntity>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEntity>>,
  TError,
  { data: Entity },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEntity>>, { data: Entity }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return createEntity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEntityMutationResult = NonNullable<Awaited<ReturnType<typeof createEntity>>>;
export type CreateEntityMutationBody = Entity;
export type CreateEntityMutationError = ErrorType<unknown>;

export const useCreateEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntity>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createEntity>>,
  TError,
  { data: Entity },
  TContext
> => {
  const mutationOptions = getCreateEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createEntityAndGetViolations = (
  entity: Entity,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityMutationResponse>(
    {
      url: `/api/entity/checkAndCreate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entity,
    },
    options,
  );
};

export const getCreateEntityAndGetViolationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityAndGetViolations>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEntityAndGetViolations>>,
  TError,
  { data: Entity },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEntityAndGetViolations>>,
    { data: Entity }
  > = (props) => {
    const { data } = props ?? {};

    return createEntityAndGetViolations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEntityAndGetViolationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createEntityAndGetViolations>>
>;
export type CreateEntityAndGetViolationsMutationBody = Entity;
export type CreateEntityAndGetViolationsMutationError = ErrorType<unknown>;

export const useCreateEntityAndGetViolations = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityAndGetViolations>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createEntityAndGetViolations>>,
  TError,
  { data: Entity },
  TContext
> => {
  const mutationOptions = getCreateEntityAndGetViolationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateEntityAndGetViolations = (
  entity: Entity,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityMutationResponse>(
    {
      url: `/api/entity/checkAndUpdate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entity,
    },
    options,
  );
};

export const getUpdateEntityAndGetViolationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityAndGetViolations>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEntityAndGetViolations>>,
  TError,
  { data: Entity },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEntityAndGetViolations>>,
    { data: Entity }
  > = (props) => {
    const { data } = props ?? {};

    return updateEntityAndGetViolations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEntityAndGetViolationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateEntityAndGetViolations>>
>;
export type UpdateEntityAndGetViolationsMutationBody = Entity;
export type UpdateEntityAndGetViolationsMutationError = ErrorType<unknown>;

export const useUpdateEntityAndGetViolations = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityAndGetViolations>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEntityAndGetViolations>>,
  TError,
  { data: Entity },
  TContext
> => {
  const mutationOptions = getUpdateEntityAndGetViolationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const cloneEntity = (
  entityType: string,
  entityId: string,
  cloneEntityBody: CloneEntityBody,
  params?: CloneEntityParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/clone/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: cloneEntityBody,
      params,
    },
    options,
  );
};

export const getCloneEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneEntity>>,
    TError,
    { entityType: string; entityId: string; data: CloneEntityBody; params?: CloneEntityParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cloneEntity>>,
  TError,
  { entityType: string; entityId: string; data: CloneEntityBody; params?: CloneEntityParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cloneEntity>>,
    { entityType: string; entityId: string; data: CloneEntityBody; params?: CloneEntityParams }
  > = (props) => {
    const { entityType, entityId, data, params } = props ?? {};

    return cloneEntity(entityType, entityId, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloneEntityMutationResult = NonNullable<Awaited<ReturnType<typeof cloneEntity>>>;
export type CloneEntityMutationBody = CloneEntityBody;
export type CloneEntityMutationError = ErrorType<unknown>;

export const useCloneEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneEntity>>,
    TError,
    { entityType: string; entityId: string; data: CloneEntityBody; params?: CloneEntityParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cloneEntity>>,
  TError,
  { entityType: string; entityId: string; data: CloneEntityBody; params?: CloneEntityParams },
  TContext
> => {
  const mutationOptions = getCloneEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const copyEntity = (
  entityCopyRequest: EntityCopyRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/copyEntity`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityCopyRequest,
    },
    options,
  );
};

export const getCopyEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof copyEntity>>,
    TError,
    { data: EntityCopyRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof copyEntity>>,
  TError,
  { data: EntityCopyRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof copyEntity>>,
    { data: EntityCopyRequest }
  > = (props) => {
    const { data } = props ?? {};

    return copyEntity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CopyEntityMutationResult = NonNullable<Awaited<ReturnType<typeof copyEntity>>>;
export type CopyEntityMutationBody = EntityCopyRequest;
export type CopyEntityMutationError = ErrorType<unknown>;

export const useCopyEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof copyEntity>>,
    TError,
    { data: EntityCopyRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof copyEntity>>,
  TError,
  { data: EntityCopyRequest },
  TContext
> => {
  const mutationOptions = getCopyEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const searchDeployedEntities = (
  entityType: string,
  query: Query,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<HitsEntity1>(
    {
      url: `/api/entity/deployed/${encodeURIComponent(String(entityType))}/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: query,
    },
    options,
  );
};

export const getSearchDeployedEntitiesQueryKey = (entityType: string, query: Query) => {
  return [`/api/entity/deployed/${entityType}/search`, query] as const;
};

export const getSearchDeployedEntitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchDeployedEntities>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchDeployedEntities>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchDeployedEntitiesQueryKey(entityType, query);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchDeployedEntities>>> = () =>
    searchDeployedEntities(entityType, query, requestOptions);

  return { queryKey, queryFn, enabled: !!entityType, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchDeployedEntities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchDeployedEntitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchDeployedEntities>>
>;
export type SearchDeployedEntitiesQueryError = ErrorType<unknown>;

export const useSearchDeployedEntities = <
  TData = Awaited<ReturnType<typeof searchDeployedEntities>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchDeployedEntities>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchDeployedEntitiesQueryOptions(entityType, query, options);

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const prefetchSearchDeployedEntities = async <
  TData = Awaited<ReturnType<typeof searchDeployedEntities>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof searchDeployedEntities>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSearchDeployedEntitiesQueryOptions(entityType, query, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const findDeployedEntityById = (
  entityType: string,
  entityId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/deployed/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getFindDeployedEntityByIdQueryKey = (entityType: string, entityId: string) => {
  return [`/api/entity/deployed/${entityType}/${entityId}`] as const;
};

export const getFindDeployedEntityByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof findDeployedEntityById>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findDeployedEntityById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFindDeployedEntityByIdQueryKey(entityType, entityId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findDeployedEntityById>>> = ({ signal }) =>
    findDeployedEntityById(entityType, entityId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(entityType && entityId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof findDeployedEntityById>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FindDeployedEntityByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof findDeployedEntityById>>
>;
export type FindDeployedEntityByIdQueryError = ErrorType<unknown>;

export const useFindDeployedEntityById = <
  TData = Awaited<ReturnType<typeof findDeployedEntityById>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findDeployedEntityById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindDeployedEntityByIdQueryOptions(entityType, entityId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindDeployedEntityById = async <
  TData = Awaited<ReturnType<typeof findDeployedEntityById>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findDeployedEntityById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindDeployedEntityByIdQueryOptions(entityType, entityId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getDistinctValues = (
  entityDistinctValuesRequest: EntityDistinctValuesRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/entity/distinct-values`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityDistinctValuesRequest,
    },
    options,
  );
};

export const getGetDistinctValuesQueryKey = (
  entityDistinctValuesRequest: EntityDistinctValuesRequest,
) => {
  return [`/api/entity/distinct-values`, entityDistinctValuesRequest] as const;
};

export const getGetDistinctValuesQueryOptions = <
  TData = Awaited<ReturnType<typeof getDistinctValues>>,
  TError = ErrorType<unknown>,
>(
  entityDistinctValuesRequest: EntityDistinctValuesRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDistinctValues>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDistinctValuesQueryKey(entityDistinctValuesRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctValues>>> = () =>
    getDistinctValues(entityDistinctValuesRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDistinctValues>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDistinctValuesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDistinctValues>>
>;
export type GetDistinctValuesQueryError = ErrorType<unknown>;

export const useGetDistinctValues = <
  TData = Awaited<ReturnType<typeof getDistinctValues>>,
  TError = ErrorType<unknown>,
>(
  entityDistinctValuesRequest: EntityDistinctValuesRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDistinctValues>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDistinctValuesQueryOptions(entityDistinctValuesRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exportEntities = (
  entityExportRequest: EntityExportRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<string>(
    {
      url: `/api/entity/exportEntities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityExportRequest,
    },
    options,
  );
};

export const getExportEntitiesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportEntities>>,
    TError,
    { data: EntityExportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportEntities>>,
  TError,
  { data: EntityExportRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportEntities>>,
    { data: EntityExportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return exportEntities(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportEntitiesMutationResult = NonNullable<Awaited<ReturnType<typeof exportEntities>>>;
export type ExportEntitiesMutationBody = EntityExportRequest;
export type ExportEntitiesMutationError = ErrorType<unknown>;

export const useExportEntities = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportEntities>>,
    TError,
    { data: EntityExportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportEntities>>,
  TError,
  { data: EntityExportRequest },
  TContext
> => {
  const mutationOptions = getExportEntitiesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getImportFieldMapping = (
  importFieldMappingRequest: ImportFieldMappingRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityImportFieldMapping[]>(
    {
      url: `/api/entity/getImportFieldMapping`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: importFieldMappingRequest,
    },
    options,
  );
};

export const getGetImportFieldMappingQueryKey = (
  importFieldMappingRequest: ImportFieldMappingRequest,
) => {
  return [`/api/entity/getImportFieldMapping`, importFieldMappingRequest] as const;
};

export const getGetImportFieldMappingQueryOptions = <
  TData = Awaited<ReturnType<typeof getImportFieldMapping>>,
  TError = ErrorType<unknown>,
>(
  importFieldMappingRequest: ImportFieldMappingRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getImportFieldMapping>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImportFieldMappingQueryKey(importFieldMappingRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImportFieldMapping>>> = () =>
    getImportFieldMapping(importFieldMappingRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getImportFieldMapping>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetImportFieldMappingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getImportFieldMapping>>
>;
export type GetImportFieldMappingQueryError = ErrorType<unknown>;

export const useGetImportFieldMapping = <
  TData = Awaited<ReturnType<typeof getImportFieldMapping>>,
  TError = ErrorType<unknown>,
>(
  importFieldMappingRequest: ImportFieldMappingRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getImportFieldMapping>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetImportFieldMappingQueryOptions(importFieldMappingRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetImportFieldMapping = async <
  TData = Awaited<ReturnType<typeof getImportFieldMapping>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  importFieldMappingRequest: ImportFieldMappingRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getImportFieldMapping>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetImportFieldMappingQueryOptions(importFieldMappingRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const importEntities = (
  entityImportRequest: EntityImportRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/entity/importEntities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityImportRequest,
    },
    options,
  );
};

export const getImportEntitiesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importEntities>>,
    TError,
    { data: EntityImportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importEntities>>,
  TError,
  { data: EntityImportRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importEntities>>,
    { data: EntityImportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return importEntities(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportEntitiesMutationResult = NonNullable<Awaited<ReturnType<typeof importEntities>>>;
export type ImportEntitiesMutationBody = EntityImportRequest;
export type ImportEntitiesMutationError = ErrorType<unknown>;

export const useImportEntities = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importEntities>>,
    TError,
    { data: EntityImportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof importEntities>>,
  TError,
  { data: EntityImportRequest },
  TContext
> => {
  const mutationOptions = getImportEntitiesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const mutateEntityId = (
  entityType: string,
  existingEntityId: string,
  newEntityId: string,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/mutateEntityId/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(existingEntityId))}/${encodeURIComponent(String(newEntityId))}`,
      method: 'POST',
    },
    options,
  );
};

export const getMutateEntityIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mutateEntityId>>,
    TError,
    { entityType: string; existingEntityId: string; newEntityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof mutateEntityId>>,
  TError,
  { entityType: string; existingEntityId: string; newEntityId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof mutateEntityId>>,
    { entityType: string; existingEntityId: string; newEntityId: string }
  > = (props) => {
    const { entityType, existingEntityId, newEntityId } = props ?? {};

    return mutateEntityId(entityType, existingEntityId, newEntityId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MutateEntityIdMutationResult = NonNullable<Awaited<ReturnType<typeof mutateEntityId>>>;

export type MutateEntityIdMutationError = ErrorType<unknown>;

export const useMutateEntityId = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mutateEntityId>>,
    TError,
    { entityType: string; existingEntityId: string; newEntityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof mutateEntityId>>,
  TError,
  { entityType: string; existingEntityId: string; newEntityId: string },
  TContext
> => {
  const mutationOptions = getMutateEntityIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const parseColumnNames = (
  entityImportRequest: EntityImportRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<string[]>(
    {
      url: `/api/entity/parseColumnNames`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityImportRequest,
    },
    options,
  );
};

export const getParseColumnNamesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof parseColumnNames>>,
    TError,
    { data: EntityImportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof parseColumnNames>>,
  TError,
  { data: EntityImportRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof parseColumnNames>>,
    { data: EntityImportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return parseColumnNames(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ParseColumnNamesMutationResult = NonNullable<
  Awaited<ReturnType<typeof parseColumnNames>>
>;
export type ParseColumnNamesMutationBody = EntityImportRequest;
export type ParseColumnNamesMutationError = ErrorType<unknown>;

export const useParseColumnNames = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof parseColumnNames>>,
    TError,
    { data: EntityImportRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof parseColumnNames>>,
  TError,
  { data: EntityImportRequest },
  TContext
> => {
  const mutationOptions = getParseColumnNamesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateEntity = (entity: Entity, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/update`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entity,
    },
    options,
  );
};

export const getUpdateEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntity>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEntity>>,
  TError,
  { data: Entity },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEntity>>, { data: Entity }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return updateEntity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEntityMutationResult = NonNullable<Awaited<ReturnType<typeof updateEntity>>>;
export type UpdateEntityMutationBody = Entity;
export type UpdateEntityMutationError = ErrorType<unknown>;

export const useUpdateEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntity>>,
    TError,
    { data: Entity },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEntity>>,
  TError,
  { data: Entity },
  TContext
> => {
  const mutationOptions = getUpdateEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const searchEntities = (
  entityType: string,
  query: Query,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<HitsEntity1>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: query,
    },
    options,
  );
};

export const getSearchEntitiesQueryKey = (entityType: string, query: Query) => {
  return [`/api/entity/${entityType}`, query] as const;
};

export const getSearchEntitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchEntities>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchEntities>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchEntitiesQueryKey(entityType, query);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchEntities>>> = () =>
    searchEntities(entityType, query, requestOptions);

  return { queryKey, queryFn, enabled: !!entityType, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchEntities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof searchEntities>>>;
export type SearchEntitiesQueryError = ErrorType<unknown>;

export const useSearchEntities = <
  TData = Awaited<ReturnType<typeof searchEntities>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchEntities>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchEntitiesQueryOptions(entityType, query, options);

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const prefetchSearchEntities = async <
  TData = Awaited<ReturnType<typeof searchEntities>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  entityType: string,
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchEntities>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSearchEntitiesQueryOptions(entityType, query, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const deleteEntity = (
  entityType: string,
  entityId: string,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<boolean>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}/delete/${encodeURIComponent(String(entityId))}`,
      method: 'POST',
    },
    options,
  );
};

export const getDeleteEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEntity>>,
    TError,
    { entityType: string; entityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEntity>>,
  TError,
  { entityType: string; entityId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEntity>>,
    { entityType: string; entityId: string }
  > = (props) => {
    const { entityType, entityId } = props ?? {};

    return deleteEntity(entityType, entityId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEntityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEntity>>>;

export type DeleteEntityMutationError = ErrorType<unknown>;

export const useDeleteEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEntity>>,
    TError,
    { entityType: string; entityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEntity>>,
  TError,
  { entityType: string; entityId: string },
  TContext
> => {
  const mutationOptions = getDeleteEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const saveBulkEntities = (
  entityType: string,
  entity: Entity[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<unknown[]>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}/saveBulkEntities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entity,
    },
    options,
  );
};

export const getSaveBulkEntitiesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveBulkEntities>>,
    TError,
    { entityType: string; data: Entity[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveBulkEntities>>,
  TError,
  { entityType: string; data: Entity[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveBulkEntities>>,
    { entityType: string; data: Entity[] }
  > = (props) => {
    const { entityType, data } = props ?? {};

    return saveBulkEntities(entityType, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveBulkEntitiesMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveBulkEntities>>
>;
export type SaveBulkEntitiesMutationBody = Entity[];
export type SaveBulkEntitiesMutationError = ErrorType<unknown>;

export const useSaveBulkEntities = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveBulkEntities>>,
    TError,
    { entityType: string; data: Entity[] },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveBulkEntities>>,
  TError,
  { entityType: string; data: Entity[] },
  TContext
> => {
  const mutationOptions = getSaveBulkEntitiesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateEntityById = (
  entityType: string,
  entityId: string,
  update: Update,
  params?: UpdateEntityByIdParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}/update/${encodeURIComponent(String(entityId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: update,
      params,
    },
    options,
  );
};

export const getUpdateEntityByIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityById>>,
    TError,
    { entityType: string; entityId: string; data: Update; params?: UpdateEntityByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEntityById>>,
  TError,
  { entityType: string; entityId: string; data: Update; params?: UpdateEntityByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEntityById>>,
    { entityType: string; entityId: string; data: Update; params?: UpdateEntityByIdParams }
  > = (props) => {
    const { entityType, entityId, data, params } = props ?? {};

    return updateEntityById(entityType, entityId, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEntityByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateEntityById>>
>;
export type UpdateEntityByIdMutationBody = Update;
export type UpdateEntityByIdMutationError = ErrorType<unknown>;

export const useUpdateEntityById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityById>>,
    TError,
    { entityType: string; entityId: string; data: Update; params?: UpdateEntityByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEntityById>>,
  TError,
  { entityType: string; entityId: string; data: Update; params?: UpdateEntityByIdParams },
  TContext
> => {
  const mutationOptions = getUpdateEntityByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const findEntityById = (
  entityType: string,
  entityId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Entity>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getFindEntityByIdQueryKey = (entityType: string, entityId: string) => {
  return [`/api/entity/${entityType}/${entityId}`] as const;
};

export const getFindEntityByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof findEntityById>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findEntityById>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindEntityByIdQueryKey(entityType, entityId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findEntityById>>> = ({ signal }) =>
    findEntityById(entityType, entityId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(entityType && entityId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof findEntityById>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FindEntityByIdQueryResult = NonNullable<Awaited<ReturnType<typeof findEntityById>>>;
export type FindEntityByIdQueryError = ErrorType<unknown>;

export const useFindEntityById = <
  TData = Awaited<ReturnType<typeof findEntityById>>,
  TError = ErrorType<unknown>,
>(
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findEntityById>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindEntityByIdQueryOptions(entityType, entityId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindEntityById = async <
  TData = Awaited<ReturnType<typeof findEntityById>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  entityType: string,
  entityId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findEntityById>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindEntityByIdQueryOptions(entityType, entityId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const deployEntity = (
  entityType: string,
  entityId: string,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<HitsEntity1>(
    {
      url: `/api/entity/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/deploy`,
      method: 'POST',
    },
    options,
  );
};

export const getDeployEntityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deployEntity>>,
    TError,
    { entityType: string; entityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deployEntity>>,
  TError,
  { entityType: string; entityId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deployEntity>>,
    { entityType: string; entityId: string }
  > = (props) => {
    const { entityType, entityId } = props ?? {};

    return deployEntity(entityType, entityId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeployEntityMutationResult = NonNullable<Awaited<ReturnType<typeof deployEntity>>>;

export type DeployEntityMutationError = ErrorType<unknown>;

export const useDeployEntity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deployEntity>>,
    TError,
    { entityType: string; entityId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deployEntity>>,
  TError,
  { entityType: string; entityId: string },
  TContext
> => {
  const mutationOptions = getDeployEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
