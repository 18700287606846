import { useEffect } from 'react';
import { useMqttClient } from '../mqttClient';

export default function useMqttSubscription(
  topic: string | undefined,
  { onMessage }: { onMessage: (message: string | Buffer) => void },
) {
  const { connectClient, client, subscribeToTopic, unsubscribeFromTopic } = useMqttClient();

  useEffect(() => {
    // preventive connect client
    void connectClient();
  }, [connectClient]);

  useEffect(() => {
    if (client && topic) {
      subscribeToTopic(topic, onMessage);

      return () => {
        unsubscribeFromTopic(topic);
      };
    }
  }, [client, onMessage, subscribeToTopic, topic, unsubscribeFromTopic]);
}
