'use client';
import dynamic from 'next/dynamic';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  type ShowDrawer,
  type Callbacks,
  DrawerContext,
  type ShowDrawerParams,
} from './drawerContext';

const Notifications = dynamic(
  () =>
    import(
      /* webpackChunkName: "Notifications" */
      '../Notifications'
    ),
);

const RenderInterfacePageDrawer = dynamic(
  () =>
    // eslint-disable-next-line import/no-cycle -- required as we need to render another interface page in this drawer template
    import(
      /* webpackChunkName: "RenderInterfacePageDrawer" */
      '../../no-code/components/RenderInterfacePageDrawer'
    ),
);

type Params = Omit<ShowDrawerParams, 'template' | 'callbacks'> & {
  activeTemplate?: ShowDrawerParams['template'];
};

const ACTIVE_TEMPLATE_TO_COMPONENT = {
  notifications: Notifications,
  interfacePage: RenderInterfacePageDrawer,
};

export function DrawerProvider({ children }: { children: React.ReactNode }) {
  const callbacksRef = useRef<Callbacks | undefined>(undefined);

  const [drawerParams, setDrawerParams] = useState<Params | undefined>(undefined);

  const { activeTemplate, componentProps, ...drawerProps } = drawerParams ?? {};
  const open = 'open' in drawerProps ? drawerProps.open : false;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- known, to be fixed later
  const Component = activeTemplate ? ACTIVE_TEMPLATE_TO_COMPONENT[activeTemplate] : null;

  const show = useCallback<ShowDrawer>(({ template, callbacks, ...restProps }) => {
    if (!template) return;

    callbacksRef.current = callbacks;

    callbacksRef.current?.onShow?.();
    setDrawerParams({ activeTemplate: template, open: true, ...restProps });
  }, []);

  const onClose = useCallback(() => {
    callbacksRef.current?.onHide?.();
    setDrawerParams({ activeTemplate: undefined, open: false });
  }, []);

  const replace = useCallback<ShowDrawer>(
    (params) => {
      callbacksRef.current?.onHide?.();

      show(params);
    },
    [show],
  );

  const value = useMemo(
    () => ({
      show,
      hide: onClose,
      replace,
      activeTemplate,
      isOpen: Boolean(open),
    }),
    [show, onClose, activeTemplate, open, replace],
  );

  return (
    <DrawerContext.Provider value={value}>
      {children}
      {Component ? (
        <Component {...componentProps} drawerProps={drawerProps} onClose={onClose} open={open} />
      ) : null}
    </DrawerContext.Provider>
  );
}
