'use client';

import { createContext } from 'react';
import type { DrawerProps } from '@unifyapps/ui/components/Drawer';
import type { ResizableDrawerProps } from '../ResizableDrawer';

export type Callbacks = {
  onShow?: (args?: unknown) => void;
  onHide?: (args?: unknown) => void;
};

export type ShowDrawerParams = {
  template: string;
  callbacks?: Callbacks;
  componentProps?: Record<string, unknown>;
} & (
  | ({ resizable?: false | undefined } & Partial<DrawerProps>)
  | ({ resizable: true } & Partial<ResizableDrawerProps>)
);

export type ShowDrawer = (params: ShowDrawerParams) => void;

type DrawerContextType = {
  isOpen: boolean;
  show: ShowDrawer;
  hide: () => void;
  replace: ShowDrawer;
  activeTemplate: string | undefined;
};

export const DrawerContext = createContext<DrawerContextType | null>(null);
