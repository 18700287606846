/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AggregationMetadata } from '../models/aggregationMetadata';
import type { AggregationMetadataRequest } from '../models/aggregationMetadataRequest';
import type { AggregationQuery } from '../models/aggregationQuery';
import type { AggregationResponse } from '../models/aggregationResponse';
import type { BatchQueryAggregationQuery } from '../models/batchQueryAggregationQuery';
import type { BatchResponseAggregationResponse } from '../models/batchResponseAggregationResponse';
import type { SourceEnvAggregateMetadataParams } from '../models/sourceEnvAggregateMetadataParams';
import type { SourceEnvAggregateParams } from '../models/sourceEnvAggregateParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const aggregate = (
  aggregationQuery: AggregationQuery,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<AggregationResponse>(
    {
      url: `/api/aggregation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationQuery,
    },
    options,
  );
};

export const getAggregateQueryKey = (aggregationQuery: AggregationQuery) => {
  return [`/api/aggregation`, aggregationQuery] as const;
};

export const getAggregateInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof aggregate>>>,
  TError = ErrorType<unknown>,
>(
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAggregateQueryKey(aggregationQuery);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof aggregate>>> = () =>
    aggregate(aggregationQuery, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof aggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AggregateInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof aggregate>>>;
export type AggregateInfiniteQueryError = ErrorType<unknown>;

export const useAggregateInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof aggregate>>>,
  TError = ErrorType<unknown>,
>(
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAggregateInfiniteQueryOptions(aggregationQuery, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchAggregateInfinite = async <
  TData = Awaited<ReturnType<typeof aggregate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAggregateInfiniteQueryOptions(aggregationQuery, options);

  await queryClient.prefetchInfiniteQuery(queryOptions);

  return queryClient;
};

export const getAggregateQueryOptions = <
  TData = Awaited<ReturnType<typeof aggregate>>,
  TError = ErrorType<unknown>,
>(
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAggregateQueryKey(aggregationQuery);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof aggregate>>> = () =>
    aggregate(aggregationQuery, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof aggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AggregateQueryResult = NonNullable<Awaited<ReturnType<typeof aggregate>>>;
export type AggregateQueryError = ErrorType<unknown>;

export const useAggregate = <
  TData = Awaited<ReturnType<typeof aggregate>>,
  TError = ErrorType<unknown>,
>(
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAggregateQueryOptions(aggregationQuery, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchAggregate = async <
  TData = Awaited<ReturnType<typeof aggregate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  aggregationQuery: AggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAggregateQueryOptions(aggregationQuery, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const batchAggregate = (
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<BatchResponseAggregationResponse>(
    {
      url: `/api/aggregation/batch`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: batchQueryAggregationQuery,
    },
    options,
  );
};

export const getBatchAggregateQueryKey = (
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
) => {
  return [`/api/aggregation/batch`, batchQueryAggregationQuery] as const;
};

export const getBatchAggregateInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof batchAggregate>>>,
  TError = ErrorType<unknown>,
>(
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchAggregateQueryKey(batchQueryAggregationQuery);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchAggregate>>> = () =>
    batchAggregate(batchQueryAggregationQuery, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchAggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchAggregateInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof batchAggregate>>
>;
export type BatchAggregateInfiniteQueryError = ErrorType<unknown>;

export const useBatchAggregateInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof batchAggregate>>>,
  TError = ErrorType<unknown>,
>(
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchAggregateInfiniteQueryOptions(batchQueryAggregationQuery, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchBatchAggregateInfinite = async <
  TData = Awaited<ReturnType<typeof batchAggregate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBatchAggregateInfiniteQueryOptions(batchQueryAggregationQuery, options);

  await queryClient.prefetchInfiniteQuery(queryOptions);

  return queryClient;
};

export const getBatchAggregateQueryOptions = <
  TData = Awaited<ReturnType<typeof batchAggregate>>,
  TError = ErrorType<unknown>,
>(
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchAggregateQueryKey(batchQueryAggregationQuery);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchAggregate>>> = () =>
    batchAggregate(batchQueryAggregationQuery, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchAggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchAggregateQueryResult = NonNullable<Awaited<ReturnType<typeof batchAggregate>>>;
export type BatchAggregateQueryError = ErrorType<unknown>;

export const useBatchAggregate = <
  TData = Awaited<ReturnType<typeof batchAggregate>>,
  TError = ErrorType<unknown>,
>(
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchAggregateQueryOptions(batchQueryAggregationQuery, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchBatchAggregate = async <
  TData = Awaited<ReturnType<typeof batchAggregate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  batchQueryAggregationQuery: BatchQueryAggregationQuery,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof batchAggregate>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBatchAggregateQueryOptions(batchQueryAggregationQuery, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const count = (
  aggregationQuery: AggregationQuery,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<number>(
    {
      url: `/api/aggregation/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationQuery,
    },
    options,
  );
};

export const getCountMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof count>>,
    TError,
    { data: AggregationQuery },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof count>>,
  TError,
  { data: AggregationQuery },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof count>>,
    { data: AggregationQuery }
  > = (props) => {
    const { data } = props ?? {};

    return count(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CountMutationResult = NonNullable<Awaited<ReturnType<typeof count>>>;
export type CountMutationBody = AggregationQuery;
export type CountMutationError = ErrorType<unknown>;

export const useCount = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof count>>,
    TError,
    { data: AggregationQuery },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof count>>,
  TError,
  { data: AggregationQuery },
  TContext
> => {
  const mutationOptions = getCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const aggregateGroups = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<string[]>({ url: `/api/aggregation/groups`, method: 'GET', signal }, options);
};

export const getAggregateGroupsQueryKey = () => {
  return [`/api/aggregation/groups`] as const;
};

export const getAggregateGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof aggregateGroups>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateGroups>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAggregateGroupsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof aggregateGroups>>> = ({ signal }) =>
    aggregateGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof aggregateGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AggregateGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof aggregateGroups>>>;
export type AggregateGroupsQueryError = ErrorType<unknown>;

export const useAggregateGroups = <
  TData = Awaited<ReturnType<typeof aggregateGroups>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateGroups>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAggregateGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchAggregateGroups = async <
  TData = Awaited<ReturnType<typeof aggregateGroups>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateGroups>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAggregateGroupsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const aggregateMetadata = (
  aggregationMetadataRequest: AggregationMetadataRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<AggregationMetadata>(
    {
      url: `/api/aggregation/metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationMetadataRequest,
    },
    options,
  );
};

export const getAggregateMetadataQueryKey = (
  aggregationMetadataRequest: AggregationMetadataRequest,
) => {
  return [`/api/aggregation/metadata`, aggregationMetadataRequest] as const;
};

export const getAggregateMetadataQueryOptions = <
  TData = Awaited<ReturnType<typeof aggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataRequest: AggregationMetadataRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateMetadata>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAggregateMetadataQueryKey(aggregationMetadataRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof aggregateMetadata>>> = () =>
    aggregateMetadata(aggregationMetadataRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof aggregateMetadata>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AggregateMetadataQueryResult = NonNullable<
  Awaited<ReturnType<typeof aggregateMetadata>>
>;
export type AggregateMetadataQueryError = ErrorType<unknown>;

export const useAggregateMetadata = <
  TData = Awaited<ReturnType<typeof aggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataRequest: AggregationMetadataRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateMetadata>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAggregateMetadataQueryOptions(aggregationMetadataRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchAggregateMetadata = async <
  TData = Awaited<ReturnType<typeof aggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  aggregationMetadataRequest: AggregationMetadataRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof aggregateMetadata>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAggregateMetadataQueryOptions(aggregationMetadataRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const sourceEnvAggregate = (
  aggregationQuery: AggregationQuery,
  params?: SourceEnvAggregateParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<AggregationResponse>(
    {
      url: `/api/aggregation/source-env`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationQuery,
      params,
    },
    options,
  );
};

export const getSourceEnvAggregateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sourceEnvAggregate>>,
    TError,
    { data: AggregationQuery; params?: SourceEnvAggregateParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sourceEnvAggregate>>,
  TError,
  { data: AggregationQuery; params?: SourceEnvAggregateParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sourceEnvAggregate>>,
    { data: AggregationQuery; params?: SourceEnvAggregateParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return sourceEnvAggregate(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SourceEnvAggregateMutationResult = NonNullable<
  Awaited<ReturnType<typeof sourceEnvAggregate>>
>;
export type SourceEnvAggregateMutationBody = AggregationQuery;
export type SourceEnvAggregateMutationError = ErrorType<unknown>;

export const useSourceEnvAggregate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sourceEnvAggregate>>,
    TError,
    { data: AggregationQuery; params?: SourceEnvAggregateParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sourceEnvAggregate>>,
  TError,
  { data: AggregationQuery; params?: SourceEnvAggregateParams },
  TContext
> => {
  const mutationOptions = getSourceEnvAggregateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sourceEnvAggregateMetadata = (
  aggregationMetadataRequest: AggregationMetadataRequest,
  params?: SourceEnvAggregateMetadataParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<AggregationMetadata>(
    {
      url: `/api/aggregation/source-env/metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aggregationMetadataRequest,
      params,
    },
    options,
  );
};

export const getSourceEnvAggregateMetadataQueryKey = (
  aggregationMetadataRequest: AggregationMetadataRequest,
  params?: SourceEnvAggregateMetadataParams,
) => {
  return [
    `/api/aggregation/source-env/metadata`,
    ...(params ? [params] : []),
    aggregationMetadataRequest,
  ] as const;
};

export const getSourceEnvAggregateMetadataQueryOptions = <
  TData = Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataRequest: AggregationMetadataRequest,
  params?: SourceEnvAggregateMetadataParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSourceEnvAggregateMetadataQueryKey(aggregationMetadataRequest, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>> = () =>
    sourceEnvAggregateMetadata(aggregationMetadataRequest, params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SourceEnvAggregateMetadataQueryResult = NonNullable<
  Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>
>;
export type SourceEnvAggregateMetadataQueryError = ErrorType<unknown>;

export const useSourceEnvAggregateMetadata = <
  TData = Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  aggregationMetadataRequest: AggregationMetadataRequest,
  params?: SourceEnvAggregateMetadataParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSourceEnvAggregateMetadataQueryOptions(
    aggregationMetadataRequest,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchSourceEnvAggregateMetadata = async <
  TData = Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  aggregationMetadataRequest: AggregationMetadataRequest,
  params?: SourceEnvAggregateMetadataParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof sourceEnvAggregateMetadata>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSourceEnvAggregateMetadataQueryOptions(
    aggregationMetadataRequest,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
