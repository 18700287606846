// ref: https://github.com/mui/material-ui/blob/57a59692df1d7016a4e46d540eb5b4ff56d83f62/packages/mui-utils/src/useEventCallback/useEventCallback.ts

'use client';
import * as React from 'react';
import useEnhancedEffect from './useEnhancedEffect';

/**
 * Inspired by https://github.com/facebook/react/issues/14099#issuecomment-440013892
 * See RFC in https://github.com/reactjs/rfcs/pull/220
 */
function useEventCallback<
  Fn extends (...args: unknown[]) => unknown = (...args: unknown[]) => unknown,
>(fn: Fn): Fn;
function useEventCallback<Args extends unknown[], Return>(
  fn: (...args: Args) => Return,
): (...args: Args) => Return;
function useEventCallback<Args extends unknown[], Return>(
  fn: (...args: Args) => Return,
): (...args: Args) => Return {
  const ref = React.useRef(fn);
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  return React.useRef((...args: Args) => (0, ref.current)(...args)).current;
}

export default useEventCallback;
