// ref: https://github.com/mui/material-ui/blob/57a59692df1d7016a4e46d540eb5b4ff56d83f62/packages/mui-utils/src/useEnhancedEffect/useEnhancedEffect.ts

'use client';
import * as React from 'react';

/**
 * A version of `React.useLayoutEffect` that does not show a warning when server-side rendering.
 * This is useful for effects that are only needed for client-side rendering but not for SSR.
 *
 * Before you use this hook, make sure to read https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * and confirm it doesn't apply to your use-case.
 */
const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default useEnhancedEffect;
