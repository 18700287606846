const convertPayloadToString = (payload: unknown) => {
  if (typeof payload === 'object') {
    return JSON.stringify(payload);
  }
  if (typeof payload === 'string') {
    return payload;
  }

  return '';
};

export const createQueryParams = (queryKey: string, payload: Record<string, unknown>) => {
  const searchParams = new URLSearchParams();
  searchParams.set(queryKey, convertPayloadToString(payload));
  return searchParams.toString();
};
