'use client';
import { createContext, useContext } from 'react';
import type { EmitActionParams } from '@unifyapps/defs/hooks/useBlockAction';
import type { ActionContextPayload } from '@unifyapps/defs/hooks/useBlockEvents';

export type OnActionArgs = Omit<EmitActionParams, 'actionContext'> & {
  actionContext: ActionContextPayload;
};

export type OnActionType = (args: OnActionArgs) => Promise<void> | Promise<boolean>;
export type ActionHandlerType = (args: OnActionArgs) => Promise<void>;
export const ActionsProviderContext = createContext<{
  doAction?: OnActionType;
}>({});

export const useActionsContext = () => {
  const context = useContext(ActionsProviderContext);
  return context;
};
