/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryClient,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { GetMqttConnectionDetailsParams } from '../models/getMqttConnectionDetailsParams';
import type { MqttConnectionDetails } from '../models/mqttConnectionDetails';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getMqttConnectionDetails = (
  params?: GetMqttConnectionDetailsParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<MqttConnectionDetails[]>(
    { url: `/api/mqtt/connectionDetails`, method: 'GET', params, signal },
    options,
  );
};

export const getGetMqttConnectionDetailsQueryKey = (params?: GetMqttConnectionDetailsParams) => {
  return [`/api/mqtt/connectionDetails`, ...(params ? [params] : [])] as const;
};

export const getGetMqttConnectionDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMqttConnectionDetails>>,
  TError = ErrorType<unknown>,
>(
  params?: GetMqttConnectionDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMqttConnectionDetails>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMqttConnectionDetailsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMqttConnectionDetails>>> = ({
    signal,
  }) => getMqttConnectionDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMqttConnectionDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMqttConnectionDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMqttConnectionDetails>>
>;
export type GetMqttConnectionDetailsQueryError = ErrorType<unknown>;

export const useGetMqttConnectionDetails = <
  TData = Awaited<ReturnType<typeof getMqttConnectionDetails>>,
  TError = ErrorType<unknown>,
>(
  params?: GetMqttConnectionDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMqttConnectionDetails>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMqttConnectionDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetMqttConnectionDetails = async <
  TData = Awaited<ReturnType<typeof getMqttConnectionDetails>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetMqttConnectionDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMqttConnectionDetails>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetMqttConnectionDetailsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
