'use client';

import React, { useMemo, useState } from 'react';
import { Snackbar } from './Snackbar';
import { SnackbarContext } from './context';
import type { SnackbarContextType, SnackbarProps } from './types';

type State = SnackbarProps & { open: boolean };

function SnackbarProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const [snackbarProps, setSnackbarProps] = useState<State>({ open: false });

  const contextValue = useMemo<SnackbarContextType>(() => {
    return {
      showSnackbar: (showSnackbarProps) => {
        setSnackbarProps({ ...showSnackbarProps, open: true });
      },
      closeSnackbar: () => {
        setSnackbarProps((prev) => ({ ...prev, open: false }));
      },
    };
  }, []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar {...snackbarProps} onClose={contextValue.closeSnackbar} />
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
