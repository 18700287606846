/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */

export type LookupByKeysRequestType =
  (typeof LookupByKeysRequestType)[keyof typeof LookupByKeysRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LookupByKeysRequestType = {
  ByKeys: 'ByKeys',
} as const;
